
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MutableInputContainer from '@/components/inputs/MutableInputContainer.vue';
import userx from '@/store/modules/userx';
import { EditState } from '@/store/models.def';
import progressToastx from '@/store/modules/progressToastx';

@Component({
	components: {
		MutableInputContainer,
	},
})
export default class MyProfile extends Vue {

	public get displayName() {
		return userx.user && userx.user.displayName;
	}
	public displayNameInput: string = '';
	public displayNameEditState: EditState = 'done';
	public validateDisplayNameNow = false;

	public get validateDisplayName(): boolean | null {
		if (!this.validateDisplayNameNow) {
			return null;
		}
		return this.displayNameInput.length >= 4;
	}

	@Watch('displayNameEditState')
	public onPathEditStateChanged(val: EditState, oldVal: EditState) {
		if (val === 'active') {
			this.displayNameInput = this.displayName || '';
		}
	}
	public async submitDisplayName() {
		this.validateDisplayNameNow = true;
		this.displayNameInput = this.displayNameInput.replace('\\', '/');
		if (!this.validateDisplayName) {
			return;
		}
		const result = await userx.updateDisplayName(this.displayNameInput);
		if (result.success) {
			this.validateDisplayNameNow = false;
			this.displayNameEditState = 'done';
		} else {
			this.displayNameEditState = 'active';
			progressToastx.error({
				title: `Profile`,
				state: `Failed to update name.`,
			});
		}
	}
}
