
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MutableInputContainer from '@/components/inputs/MutableInputContainer.vue';
import userx from '@/store/modules/userx';
import { EditState, TypicalResponse } from '@/store/models.def';
import progressToastx from '@/store/modules/progressToastx';
import {
	PasswordStrength,
	passwordStrengthRequirement,
} from '../inputs/password.def';
import PasswordInput from '../inputs/PasswordInput.vue';

@Component({
	components: {
		MutableInputContainer,
		PasswordInput,
	},
})
export default class ResetPassword extends Vue {
	public password = '';
	public oldPassword = '';
	public repeatPassword = '';
	public validatePasswordNow = false;
	public passwordStrength = PasswordStrength.Medium;
	public savePasswordError: string = '';
	public savePasswordSuccess: string = '';
	public savePasswordState: EditState = 'active';

	public get savePasswordLabel() {
		const labels = {
			active: 'Reset Password',
			sending: 'Resetting...',
			done: 'Password Reset',
		};
		return labels[this.savePasswordState];
	}

	get passwordNotSame() {
		if (!this.validatePasswordNow) {
			return false;
		}
		return this.password !== this.repeatPassword;
	}
	get passwordRequirement() {
		return this.passwordNotSame
			? 'Two passwords do not match.'
			: passwordStrengthRequirement[this.passwordStrength];
	}

	public async updatePassword() {
		if (this.savePasswordState !== 'active') {
			return;
		}
		this.validatePasswordNow = true;
		if (
			this.oldPassword !== null &&
			this.password !== null &&
			this.repeatPassword !== null &&
			this.passwordNotSame === false
		) {
			this.savePasswordState = 'sending';
			const oldPassword = this.oldPassword;
			const newPassword = this.password;
			const result = await userx.signInAndResetPassword({
				oldPassword,
				newPassword,
			});

			if (result.success) {
				this.savePasswordState = 'active';
				this.savePasswordSuccess = 'Success';
				this.savePasswordError = '';
				this.validatePasswordNow = false;
				this.oldPassword = this.password = this.repeatPassword = '';
			} else {
				this.savePasswordError = result.errorMessage!;
				this.savePasswordState = 'active';
			}
		}
	}
}
