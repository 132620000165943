
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import MyProfile from '@/components/user/MyProfile.vue';
import ResetPassword from '@/components/user/ResetPassword.vue';
@Component({
	components: {
		MyProfile,
		ResetPassword,
	},
})
export default class Profile extends Vue {}
